import { Suspense, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router";
import { UrlContext } from "../../contexts/urlContext";
import { SvgImage } from "../app/basicElements/svgImage";
import { Svg } from "../../enums/svg";
import PopupWrapper from "./popupWrapper";
import { getMainMenu } from "../../functions/api/page/getMainMenu";
import { delay } from "lodash";
import { useBarOut } from "../../functions/elements/useBarOut";
import { useIsMounted } from "../../functions/generall/useIsMounted";
import LoginModal from "./auth/loginModal";
import { useAdminLoginContext } from "../../functions/auth/useAdminLoginContext";
import { useUserLoginContext } from "../../functions/auth/useUserLoginContext";

export interface viewMainMenuInterface {
  path: string;
  title: string;
  short_title: string;
}
export function MainMenu() {
  const [open, setOpen] = useState(false);
  const [pages, setPages] = useState<viewMainMenuInterface[]>([]);
  const [loginOpen, setLoginOpen] = useState(false);
  const url = useContext(UrlContext);
  const isMounted = useIsMounted();
  const barRef = useRef(null);
  const barOut = useBarOut(barRef);
  const login = useAdminLoginContext((s) => s.isLogin);
  const userLogin = useUserLoginContext((s) => s.isLogin);
  useEffect(() => {
    if (isMounted) {
      barOut.addListener();
    } else {
      barOut.removeListener();
    }
  }, [barOut, isMounted]);
  useEffect(() => {
    if (open) {
      // load main menu data
      void getMainMenu().then((res: viewMainMenuInterface[]) => {
        setPages(res);
      });
    }
  }, [open]);

  const closePopup = () => {
    delay(switchOpen, 80);
  };

  const constructMenu = () => {
    return pages.map((page, i) => {
      return (
        <li key={"mainmenu" + page.path + i}>
          <Link
            target="_self"
            to={url + (page.path !== "" ? "/" + page.path : page.path)}
            hrefLang=""
            title={page.title}
            onClick={closePopup}
          >
            {page.short_title}
          </Link>
        </li>
      );
    });
  };

  const openLogin = () => {
    setLoginOpen(true);
  };

  const closeLogin = () => {
    setLoginOpen(false);
  };

  /*

      {loginOpen && (
        <Suspense fallback={null}>
          <LoginModal close={closeLogin} />
        </Suspense>
      )} {login || userLogin ? null : (
            <div className="jslink" onClick={openLogin}>
              <SvgImage image={Svg.login} />
            </div>
          )}
   */
  const switchOpen = () => {
    setOpen(!open);
  };
  return (
    <>
      <div id="mainmenu" className="bar" ref={barRef}>
        <div className="barbox right">
          <div className="jslink" onClick={switchOpen}>
            <SvgImage image={Svg.menu} />
          </div>
        </div>
        {open && (
          <PopupWrapper>
            <div id="menu" className="small">
              <span>Hauptmenü</span>
              <div className="container">
                <hr />
                <ul>{constructMenu()}</ul>
                <hr />
              </div>
              <button id="rt" className="jsclose" onClick={switchOpen}>
                <SvgImage image={Svg.delete} />
              </button>
            </div>
          </PopupWrapper>
        )}
      </div>
    </>
  );
}
