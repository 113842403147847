import { hydrateRoot } from "react-dom/client";
import { createElement } from "react";
import { App } from "./elements/app/core/App";
import { getCurrentPath } from "./functions/url/getCurrentPath";
import { Routing } from "./elements/app/routing/routing";
import "./functions/api/lang/localisation";
import { getCurrentHostFull } from "./functions/url/getCurrentHostFull";
import { getPage } from "./functions/api/page/getPage";
import { checkLogin } from "./functions/api/auth/checkLogin";
import { userRoles } from "./enums/userRoles";
import i18n from "i18next";

window.addEventListener("load", async () => {
  const appDiv = document.getElementById("page");
  const loginResult = await checkLogin(true);
  getPage(getCurrentPath())
    .then((result) => {
      i18n.changeLanguage(result.seo.lang);
      hydrateRoot(
        appDiv,
        createElement(Routing, {
          app: createElement(App, {
            page: result.page,
            seo: result.seo,
            openGraph: result.openGraph,
            maintenanceMode: result.maintenanceMode,
            breadcrumbs: result.breadcrumbs,
            pageDesign: result.pageDesign,
            footerData: result.footerData,
            host: getCurrentHostFull(),
            logoTitle: result.logoTitle,
            isMap: result.isMap,
            state: result.state,
            stickyContact: result.stickyContact,
            isLogin: result.isLogin,
            role: loginResult.role || userRoles.guest,
            userLogin: result.userLogin,
            isErrorPage: result.isErrorPage,
            i18nFrontend: i18n,
          }),
        }),
      );
    })
    .catch((err) => {
      console.error(err);
    });
});
