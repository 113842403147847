import classNames from "classnames";
import { imageFormats } from "../../../../enums/imageFormats";
import { useUserGridMediaUrl } from "../../../../functions/url/useUserGridMediaUrl";
import { RefObject, useEffect, useState } from "react";
import { gridStil } from "../../../../enums/edit/grid/gridStil";
import { delay } from "lodash";
import { imageSizes } from "../../../../enums/edit/imageSizes";

export default function GridPicture(props: {
  stil: gridStil;
  imageName: string;
  cacheString: string;
  mediaValues: string[];
  pictureRef: RefObject<HTMLElement>;
  imageRef: RefObject<HTMLImageElement>;
  imageSize: {
    big: {
      width: number;
      height: number;
    };
    medium: {
      width: number;
      height: number;
    };
    large: {
      width: number;
      height: number;
    };
  };
}) {
  const imageGridUrls = useUserGridMediaUrl();
  const [errorCount, setErrorCount] = useState(0);

  useEffect(() => {
    if (errorCount > 0) setErrorCount(0);
  }, [props.imageName]);
  const getImageHeight = () => {
    if (props.imageSize === undefined) {
      return "";
    }
    if (props.imageSize.medium !== undefined) {
      return props.imageSize.medium.height;
    }
    return "";
  };
  const getImageWidth = () => {
    if (props.imageSize === undefined) {
      return "";
    }
    if (props.imageSize.medium !== undefined) {
      return props.imageSize.medium.width;
    }
    return "";
  };

  const fallBack = ({ currentTarget }) => {
    if (errorCount + 1 > 6) {
      return;
    }
    setErrorCount(errorCount + 1);
    delay(() => {
      currentTarget.onerror = null;

      currentTarget.src =
        imageGridUrls.medium +
        props.imageName +
        imageFormats.jpg +
        "?" +
        (Math.random() + 1).toString(36).substring(7);
    }, 1000);
  };

  const createWidthParam = (size: "large" | "medium" | "big" | null) => {
    const param = "&w=";
    switch (size) {
      case "large":
        return param + props.imageSize.large.width || imageSizes.large;
      case "medium":
        return param + props.imageSize.medium.width || imageSizes.medium;
      case "big":
        return param + props.imageSize.big.width || imageSizes.big;
      default:
        return "";
    }
  };

  return (
    <picture
      className={classNames("gridimage", props.stil, props.mediaValues)}
      ref={props.pictureRef} //{props.parallaxCss.div}
      /*style={
        props.stil === gridStil['Bild (fest)']
          ? {opacity: 0, transition: 'opacity 0.1s ease-in-out'}
          : null
      }*/
    >
      <source
        srcSet={
          imageGridUrls.grids +
          props.imageName +
          imageFormats.webp +
          "?" +
          props.cacheString +
          createWidthParam("large")
        }
        media="( min-width: 1600px )"
        type="image/webp"
      />
      <source
        srcSet={
          imageGridUrls.grids +
          props.imageName +
          imageFormats.jpg +
          "?" +
          props.cacheString +
          createWidthParam("large")
        }
        media="( min-width: 1600px )"
        type="image/jpg"
      />
      <source
        srcSet={
          imageGridUrls.grids +
          props.imageName +
          imageFormats.webp +
          "?" +
          props.cacheString +
          createWidthParam("big")
        }
        media="( min-width: 1280px)"
        type="image/webp"
      />
      <source
        srcSet={
          imageGridUrls.grids +
          props.imageName +
          imageFormats.jpg +
          "?" +
          props.cacheString +
          createWidthParam("big")
        }
        media="( min-width: 1280px)"
        type="image/jpg"
      />
      <source
        srcSet={
          imageGridUrls.grids +
          props.imageName +
          imageFormats.webp +
          "?" +
          props.cacheString +
          createWidthParam("medium")
        }
        type="image/webp"
      />
      <source
        srcSet={
          imageGridUrls.grids +
          props.imageName +
          imageFormats.jpg +
          "?" +
          props.cacheString +
          createWidthParam("medium")
        }
        type="image/jpg"
      />
      <img
        src={
          imageGridUrls.grids +
          props.imageName +
          imageFormats.jpg +
          "?" +
          props.cacheString +
          "&w=" +
          getImageWidth()
        }
        width={getImageWidth()}
        height={getImageHeight()}
        alt="Hintergrund Bild"
        className="gridimageele"
        ref={props.imageRef}
        onError={fallBack}
      />
    </picture>
  );
}
