import { postToApi } from "../apiCommunication/apiComm";
import { apiUrls } from "../../../enums/apiUrls";
import { apiResponses } from "../../../../backend/enums/apiResponses";
import { getModulesInterface } from "../../../interfaces/getModulesInterface";
import { useAdminLoginContext } from "../../auth/useAdminLoginContext";
import i18n from "i18next";

export function getModules(): Promise<
  { id: number; short_title: string; path: string }[]
> {
  return new Promise((resolve, reject) => {
    postToApi(apiUrls.getAllModules, {
      lang: i18n.language,
    })
      .then((result: getModulesInterface) => {
        if (result.data.success === false) {
          if (result.data.error == apiResponses.notLoggedIn) {
            useAdminLoginContext((s) => s.isLoggedOut)();
          }
          reject(result.data.error);
        }
        resolve(result.data.result);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
