import { useLocation } from "react-router";
import { HeaderManager } from "../routing/headerManager";
import { BreadCrumb } from "../structure/breadCrumb";
import { wartung } from "../../../../backend/enums/database/core/seiten/wartung";
import { lazy, Suspense, useContext, useEffect } from "react";
import { PageDesignContext } from "../../../contexts/pageDesignContext";
import { Header } from "../structure/header";
import { Footer } from "../structure/footer";
import { MainMenu } from "../../popups/mainMenu";
import { ContactAndSocialMediaDataContext } from "../../../contexts/contactAndSocialMediaDataContext";
import { StickyContact } from "../buttons/stickyContact";
import { useBaseCookie } from "../../../stateManagement/cookies/useBaseCookie";
import { useIsMounted } from "../../../functions/generall/useIsMounted";
import { ParallaxProvider } from "../providers/parallaxProvider";
import { ImageZoomProvider } from "../providers/imageZoomProvider";
import { getPage } from "../../../functions/api/page/getPage";
import { getPageReturnInterface } from "../../../interfaces/page/getPageReturnInterface";
import useSWR, { SWRResponse } from "swr";
import { StatsContext } from "../../../contexts/statsContext";
import { useAnalytics } from "../../../functions/elements/useAnalytics";
import { useAdminLoginContext } from "../../../functions/auth/useAdminLoginContext";
import { userRoles } from "../../../enums/userRoles";
import { I18nextProvider } from "react-i18next";
import { i18n } from "i18next";

const AdminBar = lazy(
  () => import(/* webpackChunkName: "adminBar" */ "../../sidebar/adminBar"),
);
const MaintenanceMode = lazy(
  () =>
    import(
      /* webpackChunkName: "maintenanceMode" */ "../maintenance/MaintenanceMode"
    ),
);
const RedirectSettingsPopup = lazy(
  () =>
    import(
      /* webpackChunkName: "redirectSettingsPopup" */ "../../popups/settings/redirectSettingsPopup"
    ),
);
const CookieBanner = lazy(
  () =>
    import(/* webpackChunkName: "cookieBanner" */ "../../popups/cookieBanner"),
);

/**
 * Renders the content of the app based on the current location and login state.
 * If the page is in maintenance mode and the user is not logged in, it renders the maintenance page.
 * Otherwise, it renders the regular page content including the header, breadcrumb, main content, and footer.
 *
 */
export function AppContent({ i18nFrontend }: { i18nFrontend: i18n }) {
  const location = useLocation();
  const login = useAdminLoginContext((s) => s.isLogin);
  const role = useAdminLoginContext((s) => s.role);
  const cookie = useBaseCookie();
  const isMounted = useIsMounted();
  const statsProvider = useContext(StatsContext);
  const analytics = useAnalytics();
  const { data }: SWRResponse<getPageReturnInterface> = useSWR(
    location.pathname,
    getPage,
    {
      revalidateOnMount: false,
      revalidateOnFocus: !login,
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    if (isMounted) {
      statsProvider.prepareAndSend(); //send stats for the current page
      statsProvider.setDataSendFalse();
    }
  }, [location.pathname]);

  if (data.maintenanceMode === wartung.on && !login) {
    return (
      <Suspense fallback={null}>
        <HeaderManager
          seo={data.seo}
          openGraph={data.openGraph}
          style={data.pageDesign.pageStyle}
        />
        <MaintenanceMode title={data.seo.title} />
      </Suspense>
    );
  }
  return (
    <>
      <I18nextProvider i18n={i18nFrontend}>
        <ContactAndSocialMediaDataContext.Provider
          value={{
            contact: data.footerData.contactData,
            socialMedia: data.footerData.socialMedia,
            addressData: data.footerData.addressData,
          }}
        >
          <PageDesignContext.Provider value={data.pageDesign}>
            <HeaderManager
              seo={data.seo}
              openGraph={data.openGraph}
              style={data.pageDesign.pageStyle}
            />
            <ParallaxProvider>
              <ImageZoomProvider>
                <Header logoTitle={data.logoTitle} />
                <BreadCrumb breadCrumbs={data.breadcrumbs} />
                {data.page.main}
                <Footer isMap={data.isMap} />
              </ImageZoomProvider>
            </ParallaxProvider>
            <MainMenu />
            <StickyContact
              display={Number(data.pageDesign.fastContact) === 1}
              stickyContactData={data.stickyContact}
            />
          </PageDesignContext.Provider>
        </ContactAndSocialMediaDataContext.Provider>
      </I18nextProvider>
      {isMounted && role !== userRoles.sales && login ? (
        <Suspense fallback={null}>
          <AdminBar
            maintenanceMode={data.maintenanceMode}
            isErrorPage={data.isErrorPage}
            pageState={data.state}
          />
        </Suspense>
      ) : null}
      {isMounted && !cookie.baseCookie ? (
        <Suspense fallback={null}>
          <CookieBanner />
        </Suspense>
      ) : null}
      {login && role !== userRoles.sales && data.isErrorPage ? (
        <Suspense fallback={null}>
          <RedirectSettingsPopup
            call={location.pathname}
            isErrorPage={data.isErrorPage}
          />
        </Suspense>
      ) : null}
    </>
  );
}
