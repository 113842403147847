import {
  cloneElement,
  createElement,
  lazy,
  PropsWithChildren,
  ReactNode,
  Suspense,
  MouseEvent,
} from "react";
import { useElementEdit } from "../../../functions/elements/useElementEdit";
import { useElementAdding } from "../../../functions/elements/useElementAdding";
import { useElementMove } from "../../../functions/elements/useElementMove";
import { elementPos } from "../../../enums/edit/elementPos";
import { elementEditorModes } from "../../../enums/edit/elementEditorModes";
import { textInterface } from "../../../interfaces/elements/textInterface";
import { oListInterface } from "../../../interfaces/elements/oListInterface";
import { uListInterface } from "../../../interfaces/elements/uListInterface";
import { externalLinkInterface } from "../../../interfaces/elements/externalLinkInterface";
import { internalLinkInterface } from "../../../interfaces/elements/internalLinkInterface";
import {
  specialFunctionInterface,
  specialFunctionsTypeEnum,
} from "../../htmlElements/link/specialFunction";
import { googleMapInterface } from "../../../interfaces/elements/googleMapInterface";
import { generateRandomString } from "../../../functions/generall/generateRandomString";
import { useImageUrls } from "../../../functions/api/graphics/useImageUrls";
import { pictureInterface } from "../../../interfaces/elements/pictureInterface";
import { pdfObjectInterface } from "../../../interfaces/elements/pdfObjectInterface";
import { svgObjectInterface } from "../../../interfaces/elements/svgObjectInterface";
import { videoInterface } from "../../../interfaces/elements/videoInterface";
import { onPageFormDataType } from "../../htmlElements/onPageForms/onPageForm";
import { boxInterface } from "../../../interfaces/elements/boxInterface";
import { activeModes } from "../../../enums/edit/activeModes";
import PopupWrapper from "../../popups/popupWrapper";
import SocialLinkEdit, {
  socialLinkInterface,
} from "../../popups/edit/socialLinkEdit";
import { animationInterface } from "../../../interfaces/animations/animationInterface";
import { socialMediaTypes } from "../../../../backend/enums/database/kunden/socialMedia/socialMediaTypes";
import PaddingElementEdit from "../../popups/edit/paddingElementEdit";
import { elementPaddingInterface } from "../../htmlElements/structure/abstand";
import { CustomElementEdit } from "../../popups/edit/customElementEdit";
import { customElementInterface } from "../../../interfaces/elements/customElementInterface";
import StaticButtons, {
  staticButtons,
} from "../../popups/edit/user/staticButtons";

const TextEdit = lazy(
  () => import(/* webpackChunkName: "textEdit" */ "../../popups/edit/textEdit"),
);
const OListEditor = lazy(
  () =>
    import(/* webpackChunkName: "oListEdit" */ "../../popups/edit/oListEditor"),
);

const UListEditor = lazy(
  () =>
    import(/* webpackChunkName: "uListEdit" */ "../../popups/edit/uListEditor"),
);
const ExternalLinkEdit = lazy(
  () =>
    import(
      /* webpackChunkName: "externalLinkEdit" */ "../../popups/edit/externalLinkEdit"
    ),
);

const InternalLinkEdit = lazy(
  () =>
    import(
      /* webpackChunkName: "internalLinkEdit" */ "../../popups/edit/internalLinkEdit"
    ),
);
const SpecialFunctionEdit = lazy(
  () =>
    import(
      /* webpackChunkName: "specialFunctionEdit" */ "../../popups/edit/specialFunctionEdit"
    ),
);

const GoogleMapEdit = lazy(
  () =>
    import(
      /* webpackChunkName: "googleMapEdit" */ "../../popups/edit/googleMapEdit"
    ),
);

const ImageEdit = lazy(
  () =>
    import(
      /* webpackChunkName: "imageEdit" */ "../../popups/edit/media/imageEdit"
    ),
);

const PdfObjectEdit = lazy(
  () =>
    import(
      /* webpackChunkName: "pdfObjectEdit" */ "../../popups/edit/media/pdfObjectEdit"
    ),
);

const SvgObjectEdit = lazy(
  () =>
    import(
      /* webpackChunkName: "svgObjectEdit" */ "../../popups/edit/media/svgObjectEdit"
    ),
);

const VideoEdit = lazy(
  () =>
    import(
      /* webpackChunkName: "videoEdit" */ "../../popups/edit/media/videoEdit"
    ),
);
const OnPageForms = lazy(
  () =>
    import(
      /* webpackChunkName: "onPageForms" */ "../../popups/edit/onPageForms"
    ),
);

const Delete = lazy(
  () => import(/* webpackChunkName: "delete" */ "../../popups/edit/delete"),
);

const BoxEdit = lazy(
  () => import(/* webpackChunkName: "boxEdit" */ "../../popups/edit/boxEdit"),
);

export default function EditElement(
  props: PropsWithChildren<{
    currentElement: (isEditMode: boolean, isUpdating?: boolean) => ReactNode;
    elementProps: {
      element: string;
      id?: string;
      pos?: string;
      stil?: string;
      fileName?: string;
      cacheString?: string;
      image?: string;
      video?: string;
      file?: string;
      title?: string;
      alt?: string;
      mode?: string;
      uploadData?: {
        video?: string;
        videoName?: string;
        controls: boolean;
        function: {
          name: string;
          param: string;
        };
      };
      animations?: animationInterface[];
      type?: socialMediaTypes | specialFunctionsTypeEnum;
      formContent?: onPageFormDataType;
    };
  }>,
) {
  const elementEdit = useElementEdit(props.elementProps.id);
  const elementAdding = useElementAdding();
  const elementMove = useElementMove(props.elementProps.id);
  const urls = useImageUrls();
  const constructElement = () => {
    if (props.elementProps.element === "Box") {
      return cloneElement(
        // @ts-expect-error ignore
        props.currentElement(elementEdit.isEditMode, elementEdit.isUpdating),
        {
          ref: elementMove.setNodeRef,
          ...elementMove.setListeners(),
          ...elementMove.setAttributes(),
          style: elementMove.transformOnMove(),
          draggable: elementMove.moveModeActive ? true : null,
          onContextMenu: (e: MouseEvent) => {
            if (document.getElementById("popup") === null) {
              e.stopPropagation();
              if (elementEdit.activeMode === activeModes.edit) {
                e.preventDefault();
                elementEdit.activateEdit();
                return;
              }
            }
          },
        },
      );
    }
    return cloneElement(
      // @ts-expect-error ignore
      props.currentElement(elementEdit.isEditMode, elementEdit.isUpdating),
      {
        ref: elementMove.setNodeRef,
        ...elementMove.setListeners(),
        ...elementMove.setAttributes(),
        style: elementMove.transformOnMove(),
        draggable: elementMove.moveModeActive ? true : null,
        onClick: (e: MouseEvent) => {
          e.preventDefault();
          elementEdit.activateEdit();
        },
      },
    );
  };

  const getEditElement = () => {
    if (!elementEdit.isEditMode) {
      return null;
    }
    switch (props.elementProps.element) {
      case "H1":
      case "H2":
      case "H3":
      case "Slogan":
      case "Zitat":
      case "Text":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(TextEdit, {
            elementData: props.elementProps as textInterface,
            mode: elementEditorModes.edit,
            handleElementSaving: elementEdit.handleElementEditing,
            handleClosing: elementEdit.handleClosing,
            handleDelete: elementEdit.handleSetDelete,
          }),
        );
      case "OList":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(OListEditor, {
            elementData: props.elementProps as oListInterface,
            mode: elementEditorModes.edit,
            handleElementSaving: elementEdit.handleElementEditing,
            handleClosing: elementEdit.handleClosing,
            handleDelete: elementEdit.handleSetDelete,
          }),
        );
      case "UList":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(UListEditor, {
            elementData: props.elementProps as uListInterface,
            mode: elementEditorModes.edit,
            handleElementSaving: elementEdit.handleElementEditing,
            handleClosing: elementEdit.handleClosing,
            handleDelete: elementEdit.handleSetDelete,
          }),
        );
      case "ExternalLink":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(ExternalLinkEdit, {
            elementData: props.elementProps as externalLinkInterface,
            mode: elementEditorModes.edit,
            handleElementSaving: elementEdit.handleElementEditing,
            handleClosing: elementEdit.handleClosing,
            handleDelete: elementEdit.handleSetDelete,
          }),
        );
      case "InternalLink":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(InternalLinkEdit, {
            elementData: props.elementProps as internalLinkInterface,
            mode: elementEditorModes.edit,
            handleElementSaving: elementEdit.handleElementEditing,
            handleClosing: elementEdit.handleClosing,
            handleDelete: elementEdit.handleSetDelete,
          }),
        );
      case "SpecialFunction":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(SpecialFunctionEdit, {
            elementData:
              props.elementProps as unknown as specialFunctionInterface,
            mode: elementEditorModes.edit,
            handleElementSaving: elementEdit.handleElementEditing,
            handleClosing: elementEdit.handleClosing,
            handleDelete: elementEdit.handleSetDelete,
          }),
        );
      case "GoogleMap":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(GoogleMapEdit, {
            elementData: props.elementProps as googleMapInterface,
            mode: elementEditorModes.edit,
            handleElementSaving: elementEdit.handleElementEditing,
            handleClosing: elementEdit.handleClosing,
            handleDelete: elementEdit.handleSetDelete,
          }),
        );
      case "Image":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(ImageEdit, {
            elementData: {
              ...(props.elementProps as pictureInterface),
              file:
                urls.uploadOriginal +
                props.elementProps.fileName +
                ".webp?" +
                generateRandomString(8),
              originalFile:
                urls.uploadOriginal +
                props.elementProps.fileName +
                ".webp?" +
                generateRandomString(8),
              //if title is empty, use the title from the meta tag
              title:
                props.elementProps.title === ""
                  ? window.document.title
                  : props.elementProps.title,
              //if alt is empty, use the description from the meta tag
              alt:
                props.elementProps.alt === ""
                  ? (document.querySelector('meta[name="description"]')[
                      "content"
                    ] as string)
                  : props.elementProps.alt,
            },
            mode: elementEditorModes.edit,
            handleElementSaving: elementEdit.handleElementEditingWithFile,
            handleClosing: elementEdit.handleClosing,
            handleDelete: elementEdit.handleSetDelete,
          }),
        );
      case "PdfObject":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(PdfObjectEdit, {
            elementData: {
              ...(props.elementProps as pdfObjectInterface),
              file:
                urls.download +
                props.elementProps.file.replace(".pdf", "") +
                ".pdf",
            },
            mode: elementEditorModes.edit,
            handleElementSaving: elementEdit.handleElementEditingWithFile,
            handleClosing: elementEdit.handleClosing,
            handleDelete: elementEdit.handleSetDelete,
          }),
        );
      case "SvgObject":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(SvgObjectEdit, {
            elementData: {
              ...(props.elementProps as svgObjectInterface),
              file:
                urls.svgObject +
                props.elementProps.fileName +
                ".svg?" +
                props.elementProps.cacheString,
            },
            mode: elementEditorModes.edit,
            handleElementSaving: elementEdit.handleElementEditingWithFile,
            handleClosing: elementEdit.handleClosing,
            handleDelete: elementEdit.handleSetDelete,
          }),
        );
      case "Video":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(VideoEdit, {
            elementData: {
              ...(props.elementProps as videoInterface),
              uploadData: {
                ...(props.elementProps as videoInterface).uploadData,
                video:
                  props.elementProps.mode === "upload"
                    ? urls.video +
                      props.elementProps.uploadData.videoName +
                      ".mp4"
                    : "",
              },
            },
            mode: elementEditorModes.edit,
            handleElementSaving: elementEdit.handleElementEditingWithFile,
            handleClosing: elementEdit.handleClosing,
            handleDelete: elementEdit.handleSetDelete,
          }),
        );
      case "OnPageForm":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(OnPageForms, {
            elementData: {
              ...(props.elementProps as {
                isLive: boolean;
                element: string;
                id: string;
                formContent: onPageFormDataType;
                formId: number;
                color: string;
              }),
              formContent: [props.elementProps.formContent],
              name: "",
            },
            mode: elementEditorModes.edit,
            handleElementSaving: elementEdit.handleElementEditing,
            handleClosing: elementEdit.handleClosing,
            handleDelete: elementEdit.handleSetDelete,
          }),
        );
      case "Abstand":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(
            PopupWrapper,
            null,
            createElement(PaddingElementEdit, {
              handleDelete: elementEdit.handleDelete,
              handleClosing: elementEdit.handleClosing,
              mode: elementEditorModes.edit,
              elementData: props.elementProps as elementPaddingInterface,
              handleElementSaving: elementEdit.handleElementEditing,
            }),
          ),
        );
      case "Line":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(
            PopupWrapper,
            null,
            createElement(Delete, {
              handleDelete: elementEdit.handleDelete,
              handleClosing: elementEdit.handleClosing,
            }),
          ),
        );
      case "Box":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(BoxEdit, {
            elementData: props.elementProps as boxInterface,
            mode: elementEditorModes.edit,
            handleElementSaving: elementEdit.handleElementEditing,
            handleClosing: elementEdit.handleClosing,
            handleDelete: elementEdit.handleSetDelete,
          }),
        );
      case "SocialLink":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(SocialLinkEdit, {
            elementData: props.elementProps as socialLinkInterface,
            mode: elementEditorModes.edit,
            handleElementSaving: elementEdit.handleElementEditing,
            handleClosing: elementEdit.handleClosing,
            handleDelete: elementEdit.handleSetDelete,
          }),
        );
      case "customElement":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(CustomElementEdit, {
            elementData: props.elementProps as customElementInterface,
            mode: elementEditorModes.edit,
            handleElementSaving: elementEdit.handleElementEditing,
            handleClosing: elementEdit.handleClosing,
            handleDelete: elementEdit.handleSetDelete,
          }),
        );
      case "UserRegisterButton":
      case "UserLoginButton":
      case "UserLogoutButton":
        return createElement(
          Suspense,
          { fallback: null },
          createElement(StaticButtons, {
            elementData: props.elementProps as staticButtons,
            mode: elementEditorModes.edit,
            handleElementSaving: elementEdit.handleElementEditing,
            handleClosing: elementEdit.handleClosing,
            handleDelete: elementEdit.handleSetDelete,
          }),
        );
      default:
        return null;
    }
  };

  return (
    <>
      {elementAdding.elementAddElement(
        elementPos.before,
        props.elementProps.id,
      )}
      {elementMove.setDropBefore()}
      {constructElement()}
      {elementMove.setDropAfter()}
      {elementAdding.elementAddElement(elementPos.after, props.elementProps.id)}
      {elementEdit.editPortal(getEditElement())}
    </>
  );
}
