import { postToApi } from "../apiCommunication/apiComm";
import { apiUrls } from "../../../enums/apiUrls";
import { basicApiResponse } from "../../../interfaces/basicApiRespone";
import { apiResponses } from "../../../../backend/enums/apiResponses";
import { useAdminLoginContext } from "../../auth/useAdminLoginContext";
import i18n from "i18next";

/**
 * edit a grid element on the site
 * @param data
 * @param file
 * @param elementId
 * @param fileDimensions
 * @param fileType
 * @param svgFiles
 * @param progressBar
 */
export function editGridElement(
  data: object,
  elementId: string,
  fileType: "image" | "video" | "none",
  file?: Blob,
  fileDimensions?: object | null,
  svgFiles?: { blob: Blob; name: string }[],
  progressBar?: (progress: number) => void,
) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("elementData", JSON.stringify(data));
    formData.append("elementId", elementId);
    formData.append("lang", i18n.language);
    if (file !== undefined) {
      formData.append("file", file);
      formData.append("fileType", fileType);
      if (fileDimensions !== null) {
        formData.append("filesDimensions", JSON.stringify(fileDimensions));
      }
    }
    if (svgFiles && svgFiles.length > 0) {
      formData.append("svgCount", svgFiles.length.toString());
      svgFiles.forEach((svgFile, index) => {
        formData.append(`svgFile${index}`, svgFile.blob, svgFile.name);
      });
    }
    postToApi(apiUrls.editGridElement, formData, {
      "Content-Type": "multipart/form-data",
      onUploadProgress: (progressEvent: ProgressEvent) => {
        if (progressBar !== undefined) {
          progressBar(
            Math.round((progressEvent.loaded / progressEvent.total) * 100),
          );
        }
      },
    })
      .then((result: basicApiResponse) => {
        if (result.data.success === false) {
          if (result.data.error === apiResponses.notLoggedIn) {
            useAdminLoginContext((s) => s.isLoggedOut)();
          }
          reject(result.data.error);
        }
        resolve(result);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
