import { apiUrls } from "../../../enums/apiUrls";
import { getPageReturnInterface } from "../../../interfaces/page/getPageReturnInterface";
import { postToApi } from "../apiCommunication/apiComm";
import { Converter } from "../../../classes/converter";
import { buildSiteProps } from "../../../interfaces/buildSiteProps";
import { pageSeoData } from "../../../../backend/interfaces/requests/page/pageSeoData";
import { OpengraphData } from "../../../interfaces/settings/openGraphData";
import { AxiosResponse } from "axios";
import { breadCrumbs } from "../../../../backend/types/breadCrumbs";
import { wartung } from "../../../../backend/enums/database/core/seiten/wartung";
import { designSettingsInterfaceForPageViewing } from "../../../interfaces/settings/designSettingsInterfaceForPageViewing";
import { footerDataInterface } from "../../../../backend/functions/page/getFooterData";
import { pageStates } from "../../../../backend/enums/database/kunden/seo/pageState";
import { stickyContactInterface } from "../../../elements/popups/settings/global/editStickyContact";
import i18n from "i18next";

export function getPage(
  path: string,
  lang = i18n.language,
): Promise<getPageReturnInterface> {
  return new Promise((resolve, reject) => {
    const converter = new Converter();
    postToApi(apiUrls.getPage, {
      lang: lang,
      path: path,
    })
      .then(
        (
          res: AxiosResponse<{
            page: buildSiteProps;
            seo: pageSeoData;
            maintenanceMode: wartung;
            openGraph: OpengraphData;
            breadcrumbs: breadCrumbs;
            pageDesign: designSettingsInterfaceForPageViewing;
            footerData: footerDataInterface;
            state: pageStates;
            stickyContact: stickyContactInterface;
            logoTitle: string;
            isMap: boolean;
            isLogin: boolean; //admin
            isErrorPage: boolean;
            userLogin: boolean; //user
          }>,
        ) => {
          if (res.status !== 200) {
            //error-handling happens here
            return;
          }
          converter
            .buildSite(res.data.page)
            .then((pageData) => {
              void i18n.changeLanguage(res.data.seo.lang);
              resolve({
                page: pageData,
                seo: res.data.seo,
                maintenanceMode: res.data.maintenanceMode,
                openGraph: res.data.openGraph,
                breadcrumbs: res.data.breadcrumbs,
                pageDesign: res.data.pageDesign,
                footerData: res.data.footerData,
                isMap: res.data.isMap,
                state: res.data.state,
                isLogin: res.data.isLogin,
                isErrorPage: res.data.isErrorPage,
                stickyContact: res.data.stickyContact,
                logoTitle: res.data.logoTitle,
                userLogin: res.data.userLogin,
              });
            })
            .catch((e) => {
              reject(e);
            });
        },
      )
      .catch((e) => {
        reject(e);
      });
  });
}
