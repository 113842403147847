import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { getCurrentPath } from "../../url/getCurrentPath";
import { getCurrentHost } from "../../url/getCurrentHost";
import { refreshToken } from "../auth/refreshToken";
import { getToken, nullToken } from "../auth/token";
import { TokenResponses } from "../../../../backend/enums/tokens/tokenResponses";
import i18n from "i18next";

const axiosInstance: AxiosInstance = axios.create({
  withCredentials: true,
});
// Flag to prevent multiple token refresh requests
let isRefreshing = false;
// Define the structure of a retry queue item
interface RetryQueueItem {
  resolve: (value?) => void;
  reject: (error?) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = [];
axiosInstance.interceptors.request.use((config) => {
  if (getToken() !== null) {
    config.headers["x-auth-token"] = getToken();
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest: AxiosRequestConfig = error.config;
    if (error.response && error.response.status === 401) {
      switch (error.response.data["tokenError"]) {
        case TokenResponses.EXPIRED:
          //refresh token
          if (!isRefreshing) {
            isRefreshing = true;
            try {
              // Refresh the access token
              // Update the request headers with the new access token
              error.config.headers["x-auth-token"] = await refreshToken();

              // Retry all requests in the queue with the new token
              refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                axiosInstance
                  .request(config)
                  .then((response) => resolve(response))
                  .catch((err) => reject(err));
              });

              // Clear the queue
              refreshAndRetryQueue.length = 0;

              // Retry the original request
              return axiosInstance(originalRequest);
            } catch (refreshError) {
              // Handle token refresh error
              // You can clear all storage and redirect the user to the login page
              nullToken();
              throw refreshError;
            } finally {
              isRefreshing = false;
            }
          }

          // Add the original request to the queue
          return new Promise<void>((resolve, reject) => {
            refreshAndRetryQueue.push({
              config: originalRequest,
              resolve,
              reject,
            });
          });
      }
    }
    return Promise.reject(error);
  },
);

export function postToApi(
  url: string,
  data: object | FormData,
  options?: object,
) {
  if (data instanceof FormData) {
    data.append("host", getCurrentHost());
    data.append("path", getCurrentPath());
    data.append("lang", i18n.language);
  } else {
    data["host"] = getCurrentHost();
    if (typeof data["path"] === "undefined") {
      data["path"] = getCurrentPath();
    }
    if (typeof data["lang"] === "undefined" || data["lang"] === "") {
      data["lang"] = i18n.language;
    }
  }
  return axiosInstance.post(url, data, options);
}

export function getApi(url: string) {
  return axiosInstance.get(url);
}
