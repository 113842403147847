import { StrictMode } from "react";
import { AppContent } from "./AppContent";
import { SWRConfig } from "swr";
import { UrlContext } from "../../../contexts/urlContext";
import { StatsProvider } from "../providers/statsProvider";
import { AdminLoginProvider } from "../providers/adminLoginProvider";
import { UserLoginProvider } from "../providers/userLoginProvider";
import { GridDivCounterProvider } from "../providers/gridDivCounterProvider";
import { AppContentProps } from "../../../interfaces/appContentProps";
import { userRoles } from "../../../enums/userRoles";
import { wartung } from "../../../../backend/enums/database/core/seiten/wartung";
import { i18n } from "i18next";

export interface AppProps extends AppContentProps {
  isLogin: boolean;
  role: userRoles;
  userLogin: boolean;
  maintenanceMode: wartung;
  isErrorPage: boolean;
  i18nFrontend: i18n;
}

export function App({
  host,
  isLogin,
  role,
  userLogin,
  i18nFrontend,
  ...props
}: AppProps) {
  return (
    <StrictMode>
      <SWRConfig value={{ fallbackData: props }}>
        <StatsProvider>
          <UrlContext.Provider value={host || ""}>
            <GridDivCounterProvider>
              <AdminLoginProvider isLogin={isLogin} role={role}>
                <UserLoginProvider isLogin={userLogin}>
                  <AppContent i18nFrontend={i18nFrontend} />
                </UserLoginProvider>
              </AdminLoginProvider>
            </GridDivCounterProvider>
          </UrlContext.Provider>
        </StatsProvider>
      </SWRConfig>
    </StrictMode>
  );
}
